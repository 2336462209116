<template>
  <section class="wameed-dashboard-page-content_header">
    <div
        class="
        align-items-center
        justify-content-between
        flex-wrap
        mb-3
        pb-1

        d-none d-flex
      "
    >
      <div class="col-md-5 col-sm-12 px-0 mt-3">
        <text-input
            id="login-email"
            v-model="search"
            v-debounce:1000ms="searchItem"
            icon="search-icon"
            is-append
            input-classes="text-reg-14 "
            name="search"
            :placeholder="$t('common.search') + ' ...'"
            field-classes="mb-0 search-form-control"
        />
      </div>
      <div class="d-flex flex-wrap">
        <slot name="btns"></slot>
        <div class="mx-2 mt-3">
          <wameed-btn
              classes="  text-medium-16 rounded-8"
              :title="$t('common.filter_data')"
              type="button"
              append-icon="filter-icon"
              variant="white"
              @submitAction="filterOn = !filterOn"
          />
          <wameed-aside-modal
              :visible="filterOn"
              :content="content"
              @close="filterOn = !filterOn"
              @applyFilter="applyFilter"
              @resetFilter="resetFilter"
              :modalTitle="$t('common.filter_data')"
              :startDateLabel="$t('common.from')"
              :endDateLabel="$t('common.to')"

              :filterBtnTitle="$t('common.filter')"
              :resetBtnTitle="$t('common.reset')"
          />
        </div>
        <div class="ml-2 mt-3">
          <wameed-menu
              id="dropdown-1"
              classes=" text-medium-16"
              field-classes="mb-0 search-form-control "
              value-classes="text-medium-16"
              :title="$t('common.order_by') + ' : '"
              :sub-title="orderBy.name"
              variant="white"
              :items="orderByItems"
              text="name"
              active-item="1"
              @dropdownClick="orderApplicants"
          />
        </div>
        <div class="ml-2 mt-3" v-if="btn">
          <div

              @click="btnAction"
              class=" btn wameed-btn add-button cursor-pointer search-form-control button-header-create"
          >
            <span class="mx-2">{{ btnTitle }}</span>
            <add-circle-icon class="mx-2"/>
            <slot name="btn"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WameedMenu from '@/components/wameed/WameedMenu.vue';
import WameedBtn from '@/components/wameed/WameedBtn.vue';
import TextInput from '@/components/wameed/WameedTextInput.vue';
// import WameedAsideModal from '@/components/wameed/WameedAsideModal.vue';
import {WameedFilterModal as WameedAsideModal} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {
    WameedMenu,
    WameedBtn,
    TextInput,
    WameedAsideModal,
  },
  props: {
    subTitle: {
      type: String,
      default: null,
    },

    content: {
      type: Array,
      default: null,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    btn: {
      type: Boolean,
      default: null,
    },
    hasOrder: {
      type: Boolean,
      default: false,
    },
    canOrderByName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      filterOn: false,
      orderBy: {
        name: this.$i18n.t('common.newest'),
        id: 'desc',
      },

    };
  },
  computed: {
    orderByItems() {
      let temp = [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        },
      ];

      if (this.canOrderByName) {
        temp.push({
          name: this.$i18n.t('common.alphabetical'),
          id: 'name',
        },)
      }
      if (this.hasOrder) {
        temp.unshift({
          name: this.$i18n.t('common.default'),
          id: 'order',
        },)
      }

      return temp;
    },
  },
  methods: {
    btnAction() {
      this.$emit('btnAction');
    },
    searchItem() {
      this.$emit('orderApplicants', {
        search: this.search,
        order_by: this.orderBy.id,
      });
    },
    applyFilter() {
      this.$emit('applyFilter');
    },
    resetFilter() {
      this.$emit('resetFilter');
    },
    orderApplicants(item) {
      if (item) {
        this.orderBy = item;
      }
      this.$emit('orderApplicants', {
        search: this.search,
        order_by: this.orderBy.id,
      });
    },
  },
  created() {
    this.orderBy = this.orderByItems[0];
  }

};
</script>

<template>
  <span>
       <span v-if="value>0 || (value !=null && value.length>0)">
         {{  value.toFixed(2)  }}
       </span>
      <span v-else>0.00</span>
  </span>
</template>

<script>
export default {
  props: {
    value: null,
  }
}
</script>

<style scoped>

</style>
